//media
$desktop: 1999px;
$middle: 1199px;
$laptops: 991px;
$tablets: 767px;
$phones: 575px;
$small: 402px;

// colors
$grey: #747474;
$darkgrey: #505050;
$blue: #1473e6;
$lightblue: #1de9ff;
$yellow: #ffc617;
$yellowHover: rgb(178, 138, 16);
$white: #fff;
$input-border: #eaeaea;
$black: #333333;
$disabled: rgba(0, 0, 0, 0.12);
$disabled-text: rgba(0, 0, 0, 0.26);

// media
@function rem($px) {
    @return $px / 16px + rem;
}

@mixin desktop {
    @media screen and (max-width: $desktop) {
        @content;
    }
}
@mixin middle {
    @media screen and (max-width: $middle) {
        @content;
    }
}

@mixin laptops {
    @media screen and (max-width: $laptops) {
        @content;
    }
}

@mixin tablets {
    @media screen and (max-width: $tablets) {
        @content;
    }
}

@mixin phones {
    @media screen and (max-width: $phones) {
        @content;
    }
}
@mixin small {
    @media screen and (max-width: $small) {
        @content;
    }
}
