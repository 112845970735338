.mapbox {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 0;
  cursor: pointer;
}

.map__panel {
  position: relative;
  width: 30%;
  background-color: $white;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
  0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  padding: 45px 60px;
  z-index: 10000;
  margin: 50px;

  .form__btn {
    margin: 0 auto;
    margin-top: 30px;

    &:disabled {
      background-color: $disabled;
      pointer-events: none;
      color: $disabled-text;

      &:hover {
        box-shadow: none;
      }
    }
  }
}

.mapboxgl-control-container {
  display: none !important;
}

@media screen and (max-width: $middle) {
  .map__panel {
    width: 50%;
  }
}

@media screen and (max-width: $tablets) {
  .map__panel {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}