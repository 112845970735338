.login {
    height: 100vh;
    background: url('../../img/bg.jpg') no-repeat 50% 50% /cover;
}

.login__form {
    position: relative;
    background-color: $white;
    padding: 65px 50px;
    border-radius: 5px;
    width: 60%;
}

.login__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    flex-flow: row nowrap;
}

.content__part {
    position: relative;
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;

    //background: orange;
}

@media screen and (max-width: $middle) {
    .login__content {
        flex-flow: row wrap;
        height: auto;
    }

    .content__part {
        flex: 0 0 100%;
    }

    .login__form {
        width: 100%;
        margin: 5%;
    }
}