body {
    margin: 0;
    padding: 0;
    font-size: 16px;
    background: #fff;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body, html {
    height: 100%;

    @include tablets {
        font-size: 14px;
    }

    @include phones {
        font-size: 13px;
    }
}

*,
*:after,
*:before {
    box-sizing: border-box;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
}

a {
    color: #1976d2;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}