.header {
  .header__appbar {
    z-index: 10000;
    width: 100%;
    background-color: $white;
    position: relative;
    animation: jss1 0.5s cubic-bezier(0.230, 1.000, 0.320, 1.000) both 0.3s;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)
  }

  .header__toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}